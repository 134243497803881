import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Container from '../../components/core/Container';
import PageTitle from '../../elements/PageTitle';
import PageHeader from '../../elements/PageHeader';
import PageBody from '../../elements/PageBody';
import BackLink from '../../components/core/BackLink';
import Layout from '../../components/core/Layout';
import MediaCard from '../../components/media/MediaCard';
import CardRow from '../../components/core/CardRow';

const Landing2 = ({ data }) => {
  const {
    allMediaJson: { media },
  } = data;

  const missingImageSizes = data.allFile.edges[0].node.childImageSharp.sizes;

  return (
    <Layout>
      <Container>
        <PageHeader>
          <div>
            <PageTitle>Qaisra Khan and Dr Afifi al-Akiti on the Ka&#39;ba tile</PageTitle>
            <BackLink text="Back" />
          </div>
        </PageHeader>
        <PageBody>
          <CardRow>
            {media.map(({ item }) => (
              <MediaCard key={item.media_id} media={item} missingImageSizes={missingImageSizes} />
            ))}
          </CardRow>
        </PageBody>
      </Container>
    </Layout>
  );
};

Landing2.propTypes = {
  data: PropTypes.shape({
    allMediaJson: PropTypes.object.isRequired,
  }).isRequired,
};

export default Landing2;

export const Landing2Query = graphql`
  query Landing2Query {
    allFile(filter: { name: { eq: "missing-image" } }) {
      edges {
        node {
          ...CardImageSizesFragment
        }
      }
    }
    allMediaJson(filter: { media_id: { regex: "/^(24|25)$/" } }) {
      ...MediaSummaryFragment
    }
  }
`;
